<template>
	<div class="jobdetail">
		<div class="jobdetail-content">
			<div class="jobdetail-header">
				<div class="jobdetail-header-left">
					<div style="flex: 1;">
						<div class="jobdetail-header-title">
							<span class="job-name">{{ info.oF_POSI_NAME }}</span>
							<span class="job-salary">
								{{
                info.oF_SALARY_MY == 1
                  ? "面议"
                  : setsalary(info.oF_SALARY, info.oF_SALARYE)
              }}
							</span>
						</div>
						<div class="text-tags">
							<span>{{ info.oF_WORKAREAName }}</span>
							<span>{{ info.oF_GZJY }}经验</span>
							<span>{{ info.oF_EDU_REQ }}学历</span>
							<span>招聘人数：{{ info.oF_INVITE_NUM }}人</span>
						</div>
					</div>
					<div>
						<el-button class="follow" @click="usertype != 2?follow():''">
							{{ iscss ? "收藏职位" : "已收藏" }}
						</el-button>
						<el-button class="apply-btn"
							@click="usertype != 2? info.isSend ? '' : Addresumes(info.oF_ID):''">
							{{ usertype != 2? info.isSend ? "已投递" : "投递简历":'投递简历' }}
						</el-button>
						<!-- <cu-button @click="follow()" text="收藏职位"></cu-button>
						<cu-button @click="Addresumes(info.oF_ID)" text="投递简历" :fontSize="18" :height="44"
							:paddingLr="50" style="margin-left: 18px;"></cu-button> -->
					</div>
				</div>

				<div>
					<div class="jobdetail-header-center-warper">
						<div class="jobdetail-header-center">
							<div class="jobdetail-header-welfare" v-if="info.oF_FL != null && info.oF_FL != ''">
								<span v-for="item in fl(info.oF_FL)" :key="item">{{ item }}</span>
							</div>
						</div>
						<div class="jobdetail-write-online" @click="Required() ? goto('/usercenter'):''">填写在线简历</div>
					</div>

					<div class="jobdetail-header-view">
						<div class="time">
							<span>{{
                info.oF_UPDATE_DATE
                  ? stringToDates(info.oF_UPDATE_DATE)
                  : "一个月前"
              }}</span> 更新
							<span style="padding-left: 20px;">被浏览: {{info.oF_CLICK_NUM}}次</span>
						</div>
					</div>
				</div>
			</div>
			<div>

			</div>
			<div class="jobdetail-contain">
				<div class="jobdetail-introduce">
					<div class="introduce-item" v-if="info.oF_POSI_DESC">
						<div class="introduce-h2">职位要求</div>
						<div class="introduce-text">
							<!-- <h2>岗位要求：</h2> -->
							<p v-html="
                  info.oF_POSI_DESC ? info.oF_POSI_DESC : ''
                "></p>
						</div>

					</div>
					<div class="introduce-item" v-if="info.oF_FLZL_QT != null && info.oF_FLZL_QT != ''">
						<div class="introduce-h2">其他福利</div>
						<div class="introduce-text">
							<!-- <h2>岗位要求：</h2> -->
							<p v-html="info.oF_FLZL_QT"></p>
						</div>
					</div>

					<div class="introduce-item">
						<div class="introduce-h2">联系方式</div>
						<div class="introduce-text">
							<div class="introduce-text-list">
								<div>联系电话：</div>
								<div>{{ info.coM_PHONE }}</div>
								<div>{{ info.coM_MPHONE }}</div>
							</div>
							<div class="introduce-text-list">
								<div style="text-align-last: justify;width: 70px;">联系人：</div>
								<div>{{ midname(info.coM_LINKMAN) }}</div>
							</div>
							<div class="introduce-text-list">
								<div>企业邮箱：</div>
								<div>{{ info.coM_EMAIL }}</div>
							</div>
							<div class="introduce-text-list">
								<div>联系地址：</div>
								<div>{{ info.coM_ADDRESS }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="jobdetail-company">
					<div class="company-card">
						<div class="company-hd">
							<div class="company-logo">
								<!-- <img src="../../assets/img/logo.png" alt="" /> -->
								<img :src="setcompanylogo(info.coM_LOGO)" alt="" />
							</div>
							<div class="company-name">{{ info.coM_NAME }}</div>
							<div style="margin: 20px auto;" v-if="usertype == 1">
								<cu-button :fontSize="12" :height="28" :paddingLr="23" @click.native="favcomp()">
									{{ iscall ? "+ 关注" :"已关注"}}
								</cu-button>
							</div>
						</div>
						<div class="company-body">
							<div>
								行&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业：<span>{{info.hangye}}</span>
							</div>
							<div>
								企业类型：<span>{{ info.leixing }}</span>
							</div>
							<div v-if="info.guimo != null">
								企业规模：<span>{{ info.guimo }}</span>
							</div>
						</div>
						<div class="company-btn">
							<el-button style="width: 100%;" type="primary"
								@click="goto('/company1', { id: info.oF_COM_ID })">查看详情</el-button>
						</div>
					</div>
					<div class="post-card">
						<div class="post-title">相似岗位</div>
						<div class="post-cell">
							<div class="position-item" v-for="(item, index) in positiondata" @click="
                  jobid = item.oF_ID;
                  Getposidetails();
                " :key="index">
								<div class="position-hd">
									<div class="post-name">{{ item.oF_POSI_NAME }}</div>
									<div class="post-salary">
										{{
                      item.oF_SALARY_MY == 1
                        ? "面议"
                        : setsalary(item.oF_SALARY, item.oF_SALARYE)
                    }}
									</div>
								</div>
								<div class="position-bd">
									<div class="text-tags">
										<span>{{ item.oF_WORKAREAName }}</span>
										<span>{{ item.oF_GZJY ? item.oF_GZJY + "经验" : "不限经验" }}</span>
										<span>{{item.oF_EDU_REQ === null
                        ? "不限"
                        : item.oF_EDU_REQ + "学历"
                    }}</span>
									</div>

									<!-- <div>{{ item.oF_POSI_NATURE }}</div> -->
								</div>
								<!-- <div class="position-card">
                  <div class="card-img">
                    <img :src="setcompanylogo(item.coM_LOGO)" alt="" />
                  </div>
                  <div class="card-body">
                    <div class="card-name">{{ item.com_name }}</div>
                    <div class="card-posthot">
                      <b>{{ item.counts }}</b
                      >热招岗位
                    </div>
                  </div>
                </div> -->
							</div>
						</div>
					</div>
					<!-- <div class="ad-card">
            <img src="../../assets/img/ad5.png" alt="" />
          </div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Getposidetail,
		Getindexsearch,
		Addresume,
		Addfav,
		Cancelfav,
		AddCall,
		CancelCall
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import utils from "@/assets/js/utils";
	import maps from "@/components/map";
	import CuButton from '../../components/CuButton.vue';
	export default {
		components: {
			maps,
			CuButton
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				form: {
					name: "",
					region: "",
					industry: "",
				},
				form: {
					searchKey: "",
					region: "",
					industry: "",
					field: "",
					field1: "", //职位类型
					field2: "", //公司行业
					field3: "", //工作经验
					field4: "", //学历要求
					field5: "", //薪资要求
					field6: "", //融资阶段
					field7: "", //公司规模
					order: "", //排序
					pageNumber: 1,
					pageSize: 3,
				},
				info: {},
				jobid: "",
				iscss: false,
				iscall: false,
				postdata: {},
				positiondata: [],
				companydata: [],
				jobinfo: {},
				usertype: 0,
				userinfo: "",
			};
		},
		created() {
			this.jobid = this.$route.query.id;
			this.Getposidetails();
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.usertype = this.userinfo.userType;
			}
		},
		methods: {
			favcomp() {
				this.iscall = !this.iscall;
				var rdata = new Array();
				var userinfo = {
					peR_ID: ""
				};
				if (!isnull(localStorage.getItem("userinfo"))) {
					userinfo = JSON.parse(localStorage.getItem("userinfo"));
				}
				if (this.Required()) {
					return;
				}
				if (!this.iscall) {
					var par = {
						id: this.info.oF_COM_ID,
						uid: userinfo.peR_ID,
					};
					rdata = AddCall(par);
				} else {
					var par = {
						id: this.info.callId,
					};
					rdata = CancelCall(par);
				}
				rdata.then((res) => {
					if (res.success) {
						this.$message({
							message: "操作成功",
							type: "success",
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			midname(data) {
				if (isnull(localStorage.getItem("token"))) {
					if (isnull(data)) {
						return data;
					}
					return utils.formatName(data);
				} else {
					return data;
				}
			},
			goto(url, data) {
				this.resolvewin(url, data, 1);
			},
			stringToDates(data) {
				if (isnull(data)) {
					return "";
				}
				return utils.stringToDate(data);
			},
			follow() {
				this.iscss = !this.iscss;
				var rdata = new Array();
				var userinfo = {
					peR_ID: ""
				};
				if (!isnull(localStorage.getItem("userinfo"))) {
					userinfo = JSON.parse(localStorage.getItem("userinfo"));
				}
				if (this.Required()) {
					return;
				}
				if (!this.iscss) {
					var par = {
						id: this.info.oF_ID,
						uid: userinfo.peR_ID,
					};
					rdata = Addfav(par);
				} else {
					var par = {
						id: this.info.favId,
					};
					rdata = Cancelfav(par);
				}
				rdata.then((res) => {
					if (res.success) {
						this.$message({
							message: "操作成功",
							type: "success",
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setsalary(a, b) {
				return utils.setsalary(a, b);
			},
			Getposidetails() {
				var _this = this;
				var userinfo = {
					peR_ID: ""
				};
				if (!isnull(localStorage.getItem("userinfo"))) {
					userinfo = JSON.parse(localStorage.getItem("userinfo"));
				}
				if (isnull(_this.jobid)) {
					return false;
				}
				var uid = "";
				Getposidetail({
					id: _this.jobid,
					uid: userinfo.peR_ID
				}).then(
					(res) => {
						if (res.success) {
							_this.info = res.data.rows[0];
							if ((!_this.oF_FL || _this.oF_FL == '' || _this.oF_FL == null) && new Date(_this
									.oF_DEADLINE) > new Date()) {
								_this.oF_FL = '养老保险、医疗保险、失业保险、工伤保险、生育保险、年终奖、公费培训、节日礼金、带薪年假、高温津贴'
							}
							if (!isnull(_this.info.isFav)) {
								_this.iscss = _this.info.isFav === 0 ? true : false;
							}
							if (!isnull(_this.info.isCall)) {
								_this.iscall = _this.info.isCall === 0 ? true : false;
							}
							_this.GetList();
						} else {
							_this.$message.error(res.msg);
						}
					}
				);
			},
			GetList() {
				var _this = this;
				_this.form.field2 = _this.info.coM_INDUSTRY;
				Getindexsearch(_this.form).then((res) => {
					if (res.success) {
						_this.positiondata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/logo.png") :
					this.defimgurl + url;
			},
			//简历投递
			Addresumes(id) {
				var _this = this;
				var userinfo = {
					peR_ID: ""
				};
				if (!isnull(localStorage.getItem("userinfo"))) {
					userinfo = JSON.parse(localStorage.getItem("userinfo"));
				}
				if (this.Required()) {
					return;
				}
				var par = {
					id: id,
					uid: userinfo.peR_ID,
					showLoadType: "all",
				};
				Addresume(par).then((res) => {
					if (res.success) {
						_this.$message({
							message: "简历投递成功",
							type: "success",
						});
						_this.info.isSend = 1;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Required() {
				var userinfo = null;
				let userinfoStr = localStorage.getItem("userinfo");
				if (userinfoStr != null && userinfoStr != '') {
					userinfo = JSON.parse(userinfoStr)
				}
				if (!userinfo || !userinfo.peR_ID) {
					this.resolvewin("/login", {
						type: 1,
						isback: 1
					}, 1);
					return false;
				} else {
					if (
						!userinfo ||
						!userinfo.peR_NAME
					) {
						this.$confirm(
								"您还没有完善个人基础信息(姓名),赶快去完善吧！",
								"温馨提示", {
									confirmButtonText: "确定",
									cancelButtonText: "取消",
									type: "warning",
								}
							)
							.then(() => {
								this.resolvewin("/usercenter", {
									type: 1
								}, 1);
							})
							.catch(() => {});
						return true;
					} else {
						return false;
					}
				}
			},
			fl(data) {
				let arr = [];
				if (data != null && data != '') {
					let d = data.split(",");
					d.some((v) => {
						if (v && v != null && v != '') {
							arr.push(v)
						}
					})
				}
				return arr;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.jobdetail-write-online {
		background: url(../../assets/img/icon/iconPark-list-view@1x.png) no-repeat left center;
		padding-left: 33px;
		color: #0096FF;
		font-size: 14px;
		cursor: pointer;
	}

	.job-search {
		position: relative;
		width: 100%;
		background: #fff;
		border-top: 1px solid #eee;
		margin-bottom: 16px;

		.search-content {
			width: 1200px;
			margin: 0px auto;
			padding: 15px 0px;
			display: flex;
			justify-content: space-between;

			.search {
				flex: 1;
				width: 100%;

				.el-form {
					.el-form-item ::v-deep {
						margin-bottom: 0;

						.el-form-item__content {
							display: flex;
							align-items: stretch;

							.search-input {
								display: flex;
								border: 1px solid #eee;

								.el-input {
									.el-input__inner {
										border: none;
										width: 520px;
									}
								}

								.el-select {
									.el-input__inner {
										border: none;
										width: 140px;
									}

									.el-input::before {
										content: "";
										position: absolute;
										width: 1px;
										height: 18px;
										left: 0px;
										top: 11px;
										background: #ddd;
									}
								}
							}

							.search-btn {
								.el-button {
									background: #66b1ff;
									border-radius: 0;
									height: 100%;
									padding: 12px 30px;
									width: 150px;
								}
							}
						}
					}
				}
			}

			.search-hottel {
				display: flex;
				align-items: center;
				font-size: 14px;

				img {
					width: 22px;
					height: 22px;
					margin-right: 4px;
				}

				span {
					font-size: 18px;
					color: #66b1ff;
				}
			}
		}

		.select-content {
			width: 1200px;
			margin: 0px auto;
			padding-bottom: 15px;
			display: flex;
			justify-content: space-between;

			.select-cell {
				.el-form {
					.el-form-item ::v-deep {
						margin-bottom: 0;

						.select-input {
							.el-select {
								margin-right: 60px;

								.el-input__inner {
									width: 110px;
									padding-left: 0;
									border: none;
								}
							}
						}
					}
				}
			}

			.select-clear {
				font-size: 14px;
				color: #787878;
				line-height: 40px;
			}
		}
	}

	.time {
		padding-left: 26px;
		color: #898989;
		font-size: 12px;
		background: url(../../assets/img/icon/time.png) no-repeat left center;
		background-size: 18px 18px;
		margin-top: 15px;
	}

	.jobdetail-content {
		position: relative;
		width: 1200px;
		margin: 0px auto;
		padding: 16px 0;

		.jobdetail-header {
			padding: 24px;
			background: #fff;
			margin-bottom: 12px;
			flex-wrap: wrap;
			align-items: stretch;
			margin-bottom: 12px;

			.jobdetail-header-left {
				display: flex;

				.jobdetail-header-title {
					line-height: 32px;
					color: #333;
					margin-bottom: 16px;

					.job-name {
						font-weight: bold;
						font-size: 24px;
					}

					.job-salary {
						padding-left: 50px;
						font-size: 20px;
						font-weight: bold;
						color: #FD4A54;
					}
				}

				.jobdetail-header-view {
					display: flex;
					align-items: center;
					margin-bottom: 12px;
					line-height: 26px;

					.jobdetail-header-item {
						width: 225px;
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;

						span {
							color: #666;
						}
					}

					.jobdetail-header-welfare {
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;

						span {
							color: #666;
							margin-right: 16px;
						}
					}
				}

				.jobdetail-header-view:last-child {
					margin-bottom: 0;
				}

				.follow {
					color: #0096FF;
					border: 1px solid #0096FF;
					font-size: 18px;
					background-color: #F8F8F8;
					width: 180px;
					line-height: 44px;
					padding: 0;
					border-radius: 0;
				}

				.apply-btn {
					color: #fff;
					margin-left: 18px;
					font-size: 18px;
					width: 180px;
					line-height: 44px;
					padding: 0;
					border-radius: 0;
					border: 1px solid #0096FF;
					background-color: #0096FF;
				}
			}

			.jobdetail-header-right {
				flex: 1;
				width: 100%;
				text-align: right;
				display: flex;
				flex-wrap: wrap;

				.release-time {
					width: 100%;
					font-size: 14px;
					color: #787878;
					margin-bottom: 12px;
					height: 26px;
					line-height: 26px;
				}

				.follow-Collection {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					height: 32px;
					line-height: 32px;

					.follow {
						display: flex;
						align-items: center;
						color: #ff7630;
						cursor: pointer;

						i {
							font-size: 22px;
							margin-right: 8px;
						}
					}

					.active {
						color: #66b1ff;
					}
				}

				.apply-btn {
					width: 100%;
					align-self: flex-end;
					text-align: right;
				}
			}

			.jobdetail-header-center-warper {
				display: flex;
				align-items: center;

				.jobdetail-header-center {
					flex: 1;
					display: flex;
					align-items: center;
					margin-top: 2px;
					line-height: 26px;
					width: 100%;

					.jobdetail-header-item {
						width: 225px;
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;

						span {
							color: #666;
						}
					}

					.jobdetail-header-welfare {
						text-align: left;
						font-size: 12px;
						color: #898989;
						display: flex;
						flex-wrap: wrap;

						span {
							padding: 1px 8px;
							font-size: 12px;
							color: #fff;
							background: #3098ff;
							border-radius: 2px;
							margin-right: 12px;
							margin-top: 10px;
						}
					}
				}
			}

		}

		.jobdetail-contain {
			display: flex;
			align-items: stretch;
			position: relative;

			.jobdetail-introduce {
				padding: 8px 24px;
				background: #fff;
				flex: 1;
				width: 100%;

				.introduce-item {
					position: relative;
					padding: 16px 0;
					border-top: 1px solid #eee;

					.introduce-h2 {
						padding-left: 24px;
						position: relative;
						font-size: 18px;
						font-weight: bold;
						color: #000;
						text-align: left;
						margin-bottom: 12px;
					}

					.introduce-h2::before {
						width: 5px;
						left: 0;
						top: 2px;
						bottom: 2px;
						border-radius: 2.5px;
						background: #66b1ff;
						content: "";
						position: absolute;
					}

					.introduce-text {
						position: relative;
						text-align: left;
						font-size: 14px;
						color: #898989;
						padding-left: 24px;
						display: flex;
						flex-direction: column;

						h2 {
							font-size: 16px;
							font-weight: normal;
						}

						p {
							margin: 0px;
							padding: 0px;
							line-height: 26px;
						}

						.introduce-text-list {
							display: flex;
							align-items: center;
							margin-top: 12px;
							color: #666;

							div {
								margin-right: 10px;
							}

							span {
								display: block;
								width: 72px;
								font-size: 14px;
								color: #898989;
								text-align: right;
							}

							a {
								text-decoration: none;
								color: #ff7630;
								font-weight: bold;
							}
						}

						img {
							width: 100%;
						}
					}
				}

				.introduce-item:first-child {
					border-top: none;
				}
			}

			.jobdetail-company {
				width: 291px;
				margin-left: 16px;

				.company-card {
					background: #fff;
					border-radius: 4px;
					margin-bottom: 16px;
					padding: 20px;

					.company-hd {
						// display: flex;
						// align-items: center;
						padding-bottom: 12px;
						// border-bottom: 1px solid #eee;
						margin-bottom: 12px;
						padding-top: 12px;
						text-align: center;

						.company-logo {
							width: 64px;
							max-height: 64px;
							// display: flex;
							// justify-content: center;
							// align-items: center;
							// margin-right: 16px;
							margin: 0 auto;

							img {
								width: 100%;
							}
						}

						.company-name {
							padding-top: 10px;
							font-size: 18px;
							color: #000;
							font-weight: bold;
						}
					}

					.company-body {
						text-align: left;
						font-size: 15px;
						color: #898989;

						// border-bottom: 1px solid #eee;
						div {
							padding-bottom: 16px;
						}

						span {
							color: #666;
						}
					}

					.company-btn {
						padding-top: 16px;
					}
				}

				.post-card {
					background: #fff;
					border-radius: 4px;
					margin-bottom: 16px;
					padding: 13px;

					.post-title {
						padding-left: 24px;
						position: relative;
						font-size: 18px;
						font-weight: bold;
						color: #000;
						text-align: left;
						margin-bottom: 12px;
					}

					.post-title::before {
						width: 5px;
						left: 0;
						top: 2px;
						bottom: 2px;
						border-radius: 2.5px;
						background: #66b1ff;
						content: "";
						position: absolute;
					}

					.post-cell {
						.position-item {
							padding: 14px;
							position: relative;
							background: #F4F8FF;
							padding-bottom: 5px;
							padding-top: 5px;
							margin-bottom: 10px;

							.position-hd {
								font-size: 16px;
								display: flex;
								justify-content: space-between;
								margin-bottom: 4px;
								align-items: center;

								.post-name {
									font-size: 16px;
									color: #101010;

								}

								.post-salary {
									font-size: 18px;
									color: #F76424;
									font-weight: bold;
								}
							}

							.position-bd {
								font-size: 12px;
								color: #787878;
								margin-bottom: 12px;
								padding-top: 5px;

								.text-tags {
									span {
										font-size: 12px;
									}
								}

								.desc {
									height: 30px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: normal;
								}

								div {
									margin-bottom: 6px;
								}

							}

							.position-card {
								display: flex;
								align-items: center;
								text-align: left;
								padding-top: 12px;
								border-top: 1px dashed #ddd;

								.card-img {
									width: 32px;
									line-height: 32px;
									height: 32px;
									display: flex;
									align-items: center;
									margin-right: 12px;
									background: #fff;
									border-radius: 2px;

									img {
										width: 100%;
									}
								}

								.card-body {
									flex: 1;

									.card-name {
										font-size: 14px;
										color: #333;
									}

									.card-posthot {
										font-size: 12px;
										color: #787878;

										b {
											color: #409eff;
										}
									}
								}
							}
						}

						.position-item:last-child {
							margin-bottom: 0;
						}
					}
				}

				.ad-card {
					display: flex;
					border-radius: 4px;
					overflow: hidden;

					img {
						width: 100%;
					}
				}
			}
		}
	}
</style>
